import React, { useState, useEffect } from "react";
import { setDescription, setTitle } from "../../utils";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "../../components/Oidc/Authentication";
import styles from "./membership.module.css";
import bannerImg from "../../assets/images/banners/GE-Banner.png";
import { getPlans } from "../../utils";
import Banner from "../../components/Banner/Banner";
import { PageCard } from "../../components/PageCard/PageCard";
import MailModal from "../../components/MailModal/EnquiryModal";

const Membership = () => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [plans, setPlans] = useState(null);
    const [buildplans, setBuildPlans] = useState(null);
    const [freePlan, setFreePlan] = useState(null);

    useEffect(() => {
        setTitle("Green Economy | Membership: Join Green Economy");
        setDescription("Working with green tech firms to raise your profile, provide access to new supply chains and develop your business network.")
    }, []);

    useEffect(() => {
        const fetchPlans = async () => {
            const plans = await getPlans();
            if (plans) {
                setPlans(plans);
                let _plans = plans.filter(plan => plan.plan_code.includes("BUILD"));
                _plans = _plans.sort((a, b) => {
                    return parseInt(a.recurring_price) - parseInt(b.recurring_price);
                })
                setBuildPlans(_plans);
                let free = plans.find(p => p.plan_code === "MARKETPLACE");
                setFreePlan(free);
            }
        };
        fetchPlans();
    }, []);

    const handleClick = () => {
        handleShow();
    };

    const getSinglePlan = (planCode) => {
        let _plan = plans.find(p => p.plan_code === planCode);
        return _plan;
    }
    const openInNewTab = url => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    const handleFreeClick = (event, code) => {
        let _plan = getSinglePlan(code);
        openInNewTab(_plan.url);
    }

    const getPlanURL = (code) => {
        return getSinglePlan(code).url;
    }

    return (

        <div>
            <Banner
                title="MEMBERSHIP"
                text="CREATE YOUR MARKETPLACE PROFILE AND GAIN"
                subText="ACCESS TO A HOST OF BUSINESS GROWTH BENEFITS"
                src={bannerImg}
                alt="Membership"
                color='white'
                bgcolour="#02be7c"
            />
            <div className={styles.memberContainer}>
                <h2><b>Join Green Economy</b></h2>
                <p className="mb-4 FirtsPara">Do you install, supply, design, manufacture or distribute green technology and services? Green Economy brings together the installers, tradespeople and professionals we need to get the UK on the path to a sustainable, net zero economy into one single, searchable network.</p>
                <h4 className="mb-2"><b>Green Economy offers two levels of membership:</b> </h4>

                <div className="mb-4">
                    <UnauthenticatedTemplate>
                        <PageCard
                            width="100%"
                            header="Free Spark Membership  - Create a full, free profile on our marketplace to increase your visibility and to access direct sales enquiries through our site."
                            text={freePlan?.description}
                            subText={freePlan?.store_markup_description.split('|')}
                            page="public"
                            hasLogo={false}
                            link={freePlan?.url}
                            styling="standardNoImageOver"
                            btnText={freePlan?.name}
                            externalLink={true}
                            listsubtext={true}
                        />
                    </UnauthenticatedTemplate>
                    <AuthenticatedTemplate>
                        <PageCard
                            width="100%"
                            header="Spark - Create a full, free profile on our marketplace to increase your visibility and to access direct sales enquiries through our site."
                            text={freePlan?.description}
                            subText={freePlan?.store_markup_description.split('|')}
                            page="public"
                            hasLogo={false}
                            styling="subSingle"
                            listsubtext={true}
                        />
                    </AuthenticatedTemplate>
                </div>

                <div className="mb-2 w-100" style={{ textAlign: 'center' }} >
                    <PageCard
                        width="100%"
                        header="Full membership &ldquo;Build&rdquo; enhances the Spark package, with additional sales opportunities and profile authentication"
                        text="This enhanced package provides a host of opportunities to drive business growth including these benefits:"
                        subText={["A searchable profile on the Marketplace with direct enquiries from buyers (included with free profile) ",
                            "Access to exclusive sales and tender opportunities, including a weekly email of the latest opportunities",
                            "Networking and events, including invitations to Meet the Buyer sessions",
                            "Complimentary access to the Green Leaders events programme", "Additional resources including market intelligence and business support guidance.",
                            "A Green Economy Approved badge added to your profile, which certifies your expertise and accreditations to instil confidence with potential buyers. Enquire for more details. "]}
                        hasLogo={false}
                        styling="standardNoImage"
                        listsubtext={true}
                    />
                </div>

                <div className={styles.memberCards}>
                    {
                        buildplans?.map(plan => (                           
                            <div key={plan.plan_code} className={styles.memberCard}>
                                <UnauthenticatedTemplate>
                                    <PageCard
                                        width="100%"
                                        header={"&#163;" + plan.recurring_price + ".00 per " + plan.interval_unit.replace("s","")}
                                        text={plan.description}
                                        subText={plan.store_markup_description.split('|')}
                                        page="public"
                                        hasLogo={false}
                                        link={plan.url}
                                        styling="sub"
                                        btnText={plan.name}
                                        externalLink={true}
                                        listsubtext={true}
                                    />
                                </UnauthenticatedTemplate>
                                <AuthenticatedTemplate>
                                    <PageCard
                                        width="100%"
                                        header={"&#163;" + plan.recurring_price + ".00 - You are already registered."}
                                        text={plan.description}
                                        subText={plan.store_markup_description.split('|')}
                                        page="public"
                                        hasLogo={false}
                                        styling="sub"
                                        listsubtext={true}
                                    />
                                </AuthenticatedTemplate>
                            </div>
                        ))
                    }
                </div>
                <p className="mb-2">Eligible businesses operating in <b>Greater Manchester</b> receive this package <b>free of charge</b>, as it is fully-funded by the GC Business Growth Hub.&nbsp;
                    <a href="https://www.businessgrowthhub.com/who-we-are/fully-funded" className="PLinkTo" target='_blank' rel="noopener noreferrer">Fully-Funded support | GC Business Growth Hub</a>
                </p>
                <p className="mb-2">For the definition of business size.&nbsp;
                    <a href="https://www.gov.uk/government/publications/fcdo-small-to-medium-sized-enterprise-sme-action-plan/small-to-medium-sized-enterprise-sme-action-plan#what-is-an-sme" className="PLinkTo" target='_blank' rel="noopener noreferrer">visit the UK Government's website.</a>
                </p>
                <MailModal show={show} handleClose={handleClose} title="Membership Enquiry" email="info@greeneconomy.co.uk" template={3} />
                <p><a onClick={handleClick} className="PLinkTo">For all membership enquiries</a></p>

            </div>
        </div>
    );
};

export default Membership;