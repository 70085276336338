import { useState } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Styles from "./NavMenu.module.css";
import logo from "../../assets/images/pageIcons/ge-logo-lge.png";
import dougnut from "../../assets/images/banners/bg-footer-doughnut.png";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "../Oidc/Authentication";
import Login from "../Oidc/Login";
import {Logout} from "../Oidc/Logout";
import "./NavMenu.css";
import { Link, useLocation } from "react-router-dom";


export const NavMenu = () => {
    const location = useLocation();
    const path = location.pathname;

    const [showNewsMenu, setShowNewsMenu] = useState(false);
    const handleNewsClick = (menuclick) => {
        if (menuclick === true)
            setShowNewsMenu(false)
        else
            setShowNewsMenu(!showNewsMenu)
    }

    const [showServiceSubMenu, setShowServiceSubMenu] = useState(false);
    const handleClick = (ev, menuclick) => {
        if (menuclick === true) {
            setShowServiceSubMenu(false);
            setMainIsShown(true);
            setSalesIsShown(false);
            setMarketIsShown(false);
            setNetZeroIsShown(false);
            setGrowGreenIsShown(false);
            setRIIsShown(false);
            setConsultIsShown(false);
            setGSIsShown(false);
            setAccrIsShown(false);
            setStrategicIsShown(false);
        } else
            setShowServiceSubMenu(!showServiceSubMenu)
    }

    const [isMainShown, setMainIsShown] = useState(true);
    const [isMarketShown, setMarketIsShown] = useState(false);
    const [isSalesShown, setSalesIsShown] = useState(false);
    const [isNetZeroShown, setNetZeroIsShown] = useState(false);
    const [isGrowGreenShown, setGrowGreenIsShown] = useState(false);
    const [isRIShown, setRIIsShown] = useState(false);
    const [isConsultShown, setConsultIsShown] = useState(false);
    const [isGSShown, setGSIsShown] = useState(false);
    const [isAccrShown, setAccrIsShown] = useState(false);
    const [isNewsShown, setNewsIsShown] = useState(false);
    const [isCaseShown, setCaseIsShown] = useState(false);
    const [isInsightShown, setInsightIsShown] = useState(false);
    const [isReportsShown, setReportsIsShown] = useState(false);
    const [isResourcesShown, setResourcesIsShown] = useState(false);
    const [isStrategicShown, setStrategicIsShown] = useState(false);
    
 
    function changeHighLightText(showArea, hideArea) {

        switch(showArea){
            case 'Main':
                setMainIsShown(true);
                break;
            case 'Sales':
                setSalesIsShown(true);
                break;
            case 'Market':
                setMarketIsShown(true);
                break;
            case 'NetZero':
                setNetZeroIsShown(true);
                break;
            case 'GrowGreen':
                setGrowGreenIsShown(true);
                break;
            case 'RI':
                setRIIsShown(true);
                break;
            case 'Consult':
                setConsultIsShown(true);
                break;
            case 'GI':
                setGSIsShown(true);
                break;
            case 'Accr':
                setAccrIsShown(true);
                break;
            case 'News':
                setNewsIsShown(true);
                break;
            case 'Case':
                setCaseIsShown(true);
                break;
            case 'Insights':
                setInsightIsShown(true);
                break;
            case 'Reports':
                setReportsIsShown(true);
                break;
            case 'Resource':
                setResourcesIsShown(true);
                break;
            case 'Strag':
                setStrategicIsShown(true);
                break;
            default:
                break;
    
        }
        switch (hideArea) {
            case 'Main':
                setMainIsShown(false);
                break;
            case 'Sales':
                setSalesIsShown(false);
                break;
            case 'Market':
                setMarketIsShown(false);
                break;
            case 'NetZero':
                setNetZeroIsShown(false);
                break;
            case 'GrowGreen':
                setGrowGreenIsShown(false);
                break;
            case 'RI':
                setRIIsShown(false);
                break;
            case 'Consult':
                setConsultIsShown(false);
                break;
            case 'GI':
                setGSIsShown(false);
                break;
            case 'Accr':
                setAccrIsShown(false);
                break;
            case 'News':
                setNewsIsShown(false);
                break;
            case 'Case':
                setCaseIsShown(false);
                break;
            case 'Insights':
                setInsightIsShown(false);
                break;
            case 'Reports':
                setReportsIsShown(false);
                break;
            case 'Resource':
                setResourcesIsShown(false);
                break;
            case 'Strag':
                setStrategicIsShown(false);
                break;
            default:
                break;

        }
    }

    return (
        <>
            <Navbar variant="dark" expand="lg" className={`${Styles.navbar} navbar`}>
                <Navbar.Brand className={Styles.brand} href={process.env.REACT_APP_GI_URI}>
                    <img className={Styles.logo} src={logo} alt="logo" />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav" className={Styles.navLinkContainer}>
                    <Nav>
                        {/*Public pages*/}
                        <UnauthenticatedTemplate>
                            <div className={Styles.navItemsContainer}>
                                <Link
                                    className={`${Styles.navLink} ${path === "/about-us" ? Styles.current : null
                                        }`}
                                    as={Link}
                                    to="/about-us"
                                    href="/about-us"
                                    onClick={event => { handleClick(event, true); handleNewsClick(true); }}
                                >
                                    About Us
                                </Link>
                                <Link
                                    className={`${Styles.navLink} ${showServiceSubMenu === true || path.includes("/suppliers") || path === "/marketplace" || path === "/buyers" || path === "/accreditations" || path === "/public" || path === "/research" ? Styles.current : null}`}
                                    as={Link}
                                    onClick={event => { handleClick(event, false); handleNewsClick(true); }}
                                >
                                    Services
                                </Link>

                                <Link
                                    className={`${Styles.navLink} ${path === "/events" ? Styles.current : null}`}
                                    as={Link}
                                    to="/events"
                                    href="/events"
                                    onClick={event => { handleClick(event, true); handleNewsClick(true); }}
                                >
                                    Events
                                </Link>
                                <Link
                                    className={`${Styles.navLink} ${showNewsMenu === true ? Styles.current : null}`}
                                    as={Link}
                                    onClick={event => { handleClick(event, true); handleNewsClick(false); }}
                                >
                                    Green Intelligence
                                </Link>
                                <Link
                                    className={`${Styles.navLink} ${path.includes("/membership") ? Styles.current : null}`}
                                    as={Link}
                                    to="/membership"
                                    href="/membership"
                                    onClick={event => { handleClick(event, true); handleNewsClick(true); }}
                                >
                                    Membership
                                </Link>
                            </div>
                            {/*{process.env.REACT_APP_GI_MEMBERSHIP_ENABLED === "true" &&*/}
                            {/*   <Login />*/}
                            {/* }*/}

                        </UnauthenticatedTemplate>

                        {/*Public & Private pages*/}
                        <AuthenticatedTemplate>

                            <div className={Styles.navItemsContainer}>
                                <Link
                                    className={`${Styles.navLink} ${path === "/about-us" ? Styles.current : null
                                        }`}
                                    as={Link}
                                    to="/about-us"
                                    href="/about-us"
                                    onClick={event => { handleClick(event, true); handleNewsClick(true); }}
                                >
                                    About Us
                                </Link>
                                <Link
                                    className={`${Styles.navLink} ${showServiceSubMenu === true || path.includes("/suppliers") || path === "/marketplace" || path === "/buyers" || path === "/accreditations" || path === "/public" || path === "/research" ? Styles.current : null}`}
                                    as={Link}
                                    onClick={event => { handleClick(event, false); handleNewsClick(true); }}
                                >
                                    Services
                                </Link>

                                <Link
                                    className={`${Styles.navLink} ${path === "/events" ? Styles.current : null}`}
                                    as={Link}
                                    to="/events"
                                    href="/events"
                                    onClick={event => { handleClick(event, true); handleNewsClick(true); }}
                                >
                                    Events
                                </Link>
                                {/*<a className={`${Styles.navLink} ${path === "/news" ? Styles.current : null}`}*/}
                                {/*    href={process.env.REACT_APP_GI_URI + "/news"} target="_blank" rel="noopener noreferrer" onClick={event => handleClick(event, true)}>*/}
                                {/*    News*/}
                                {/*</a>*/}
                                <Link
                                    className={`${Styles.navLink} ${showNewsMenu === true ? Styles.current : null}`}
                                    as={Link}
                                    onClick={event => { handleClick(event, true); handleNewsClick(false); }}
                                >
                                    Green Intelligence
                                </Link>
                                <Link
                                    className={`${Styles.navLink} ${path.includes("/membership") ? Styles.current : null}`}
                                    as={Link}
                                    to="/membership"
                                    href="/membership"
                                    onClick={event => { handleClick(event, true); handleNewsClick(true); }}
                                >
                                    Membership
                                </Link>

                                {/*<Link*/}
                                {/*    className={`${Styles.navLink} ${path.includes("/dashboard") ? Styles.current : null}`}*/}
                                {/*    as={Link}*/}
                                {/*    to="/dashboard"*/}
                                {/*    href="/dashboard"*/}
                                {/*>*/}
                                {/*    Dashboard*/}
                                {/*</Link>*/}
                            </div>
                            {/*{process.env.REACT_APP_GI_MEMBERSHIP_ENABLED === "true" &&*/}
                            {/*    <Logout />*/}
                            {/*}*/}
                        </AuthenticatedTemplate>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
            {
                showNewsMenu &&
                <div className={`${Styles.subMenuContainer} ${showNewsMenu ? 'SubMenuActive' : ''}`} style={{ backgroundImage: `url(${dougnut})` }}>
                    <div className={Styles.subMenuBlurb}>
                        <h3>Green Intelligence</h3>
                        <p>Green Intelligence, powered by Green Economy, has been keeping businesses in the know since 2013. With the latest environmental legislation and sector news,
                            along with market intelligence and thought leadership from green business experts, we are here to help you navigate the complex and fast paced green economy.</p>
                    </div>
                    <div className={Styles.subNewsMenu}>
                        <a className={`${Styles.navLinkLeft} ${path === "/news" ? Styles.current : null}`}
                                href={process.env.REACT_APP_GI_URI + "/news"} onClick={event => handleClick(event, true)}
                                onMouseOver={() => changeHighLightText('News', 'Main')}
                                onMouseLeave={() => changeHighLightText('Main', 'News')}
                            >
                            News
                        </a>
                        <a className={`${Styles.navLinkLeft} ${path === "/news" ? Styles.current : null}`}
                                href={process.env.REACT_APP_GI_URI + "/case-studies"} onClick={event => handleClick(event, true)}
                                onMouseOver={() => changeHighLightText('Case', 'Main')}
                                onMouseLeave={() => changeHighLightText('Main', 'Case')}
                            >
                            Case Studies
                        </a>
                        <a className={`${Styles.navLinkLeft} ${path === "/news" ? Styles.current : null}`}
                                href={process.env.REACT_APP_GI_URI + "/insights"} onClick={event => handleClick(event, true)}
                                onMouseOver={() => changeHighLightText('Insights', 'Main')}
                                onMouseLeave={() => changeHighLightText('Main', 'Insights')}
                            >
                            Insights
                        </a>
                        <a className={`${Styles.navLinkLeft} ${path === "/news" ? Styles.current : null}`}
                                href={process.env.REACT_APP_GI_URI + "/reports"} onClick={event => handleClick(event, true)}
                                onMouseOver={() => changeHighLightText('Reports', 'Main')}
                                onMouseLeave={() => changeHighLightText('Main', 'Reports')}
                            >
                            Reports
                            </a>
                        <a className={`${Styles.navLinkLeft} ${path === "/resources" ? Styles.current : null}`}
                                href={process.env.REACT_APP_GI_URI + "/resources"} onClick={event => handleClick(event, true)}
                                onMouseOver={() => changeHighLightText('Resource', 'Main')}
                                onMouseLeave={() => changeHighLightText('Main', 'Resource')}
                            >
                            Resources
                        </a>
                    </div>
                        <div className={Styles.subSubMenu}>
                            {isMainShown &&
                                <div id="MainText">
                                    &nbsp;
                                </div>
                            }
                            {isNewsShown &&
                                <div id="NewsText">
                                    <h3>The latest environmental business news</h3>
                                    <p style={{}} className={Styles.textDesc}>
                                        Stay on top of the latest industry trends, policy and guidance effecting your business. Explore market opportunities for green tech firms or stay in loop on net zero strategies for your business.
                                    </p>
                                </div>
                            }
                            {isCaseShown &&
                                <div id="CaseText">
                                    <h3>Get inspired by real businesses making a difference</h3>
                                    <p style={{}} className={Styles.textDesc}>
                                        In our case studies we highlight the amazing innovation at the root of the net zero transition, showcase some of the collaborations delivering impacts and highlight the people developing net zero products and services. 
                                    </p>
                                </div>
                            }
                            {isInsightShown &&
                                <div id="InsightText">
                                    <h3>Hear expertise from business leaders, industry insiders and sector specialists</h3>
                                    <p style={{}} className={Styles.textDesc}>
                                        Our team works directly with the businesses making waves in the transition to net zero. Read thought leadership on the technical challenges, the market opportunities and the big issues effecting the journey to net zero and the uptake of low carbon technology. 
                                    </p>
                                </div>
                            }
                            {isReportsShown &&
                                <div id="ReportText">
                                    <h3>Exploring the green tech sector to increase confidence in the net zero transition</h3>
                                    <p style={{}} className={Styles.textDesc}>
                                        We publish an annual report mapping the green technologies and services sector, as well as delivering commissions for our members and partners which includes market research, consumer trends, sales forecasting, lead generation and competitor analysis.
                                    </p>
                                </div>
                            }
                            {isResourcesShown &&
                                <div id="ResourceText">
                                    <h3>Business guides to drive green business growth and demystify green technologies</h3>
                                    <p style={{}} className={Styles.textDesc}>
                                        Our guides provide businesses with resources to aid the transition to net zero including technology explainers, guides for new entrants to the sector and customer care guides for existing firms.  
                                    </p>
                                </div>
                            }
                    </div>
                </div>
            }
            {
                showServiceSubMenu &&
                <div className={`${Styles.subMenuContainer} ${showServiceSubMenu ? 'SubMenuActive' : ''}`} style={{ backgroundImage: `url(${dougnut})` }}>
                        <div className={Styles.subMenuBlurb}>
                            <h3>Services</h3>
                            <p>Green Economy works with buyers and suppliers of low carbon products and services to improve access to the technologies and services that will facilitate the transition to net zero.
                                We also work with local and regional policy makers to grow your local green economy. </p>
                        </div>
                        <div className={Styles.subMenuMenu}>
                            <a className={`${Styles.navLinkLeft} ${path === "/services/transition-to-net-zero" ? Styles.current : null}`}
                                href={process.env.REACT_APP_GI_URI + "/services/transition-to-net-zero"} onClick={event => handleClick(event, true)}
                                onMouseOver={() => changeHighLightText('NetZero', 'Main')}
                                onMouseLeave={() => changeHighLightText('Main', 'NetZero')}
                            >
                                Transition to net zero
                            </a>
                            {/*<Link*/}
                            {/*    className={`${Styles.navLinkLeft}`}*/}
                            {/*    as={Link}*/}
                            {/*    to="/buyers"*/}
                            {/*    href="/buyers"*/}
                            {/*    onMouseOver={() => changeHighLightText('NetZero', 'Main')}*/}
                            {/*    onMouseLeave={() => changeHighLightText('Main', 'NetZero')}*/}
                            {/*    onClick={(event) => handleClick(event, true)}*/}
                            {/*>*/}
                            {/*    Transition to net zero*/}
                            {/*</Link>*/}
                            <a className={`${Styles.navLinkLeft} ${path === "services/green-business-growth" ? Styles.current : null}`}
                                href={process.env.REACT_APP_GI_URI + "/services/green-business-growth"} onClick={event => handleClick(event, true)}
                                onMouseOver={() => changeHighLightText('Sales', 'Main')}
                                onMouseLeave={() => changeHighLightText('Main', 'Sales')}
                            >
                                Green business growth
                            </a>
                            {/*<Link*/}
                            {/*    className={`${Styles.navLinkLeft}`}*/}
                            {/*    as={Link}*/}
                            {/*    to="/suppliers"*/}
                            {/*    href="/suppliers"*/}
                            {/*    onMouseOver={() => changeHighLightText('Sales', 'Main')}*/}
                            {/*    onMouseLeave={() => changeHighLightText('Main', 'Sales')}*/}
                            {/*    onClick={(event) => handleClick(event, true)}*/}
                            {/*>*/}
                            {/*    Green business growth*/}
                            {/*</Link>*/}
                            <a className={`${Styles.navLinkLeft} ${path === "services/grow-your-green-economy" ? Styles.current : null}`}
                                href={process.env.REACT_APP_GI_URI + "services/grow-your-green-economy/"} onClick={event => handleClick(event, true)}
                                onMouseOver={() => changeHighLightText('GrowGreen', 'Main')}
                                onMouseLeave={() => changeHighLightText('Main', 'GrowGreen')}
                            >
                                Grow your green economy
                            </a>
                            {/*<Link*/}
                            {/*    className={`${Styles.navLinkLeft}`}*/}
                            {/*    as={Link}*/}
                            {/*    to="/public"*/}
                            {/*    href="/public"*/}
                            {/*    onMouseOver={() => changeHighLightText('GrowGreen', 'Main')}*/}
                            {/*    onMouseLeave={() => changeHighLightText('Main', 'GrowGreen')}*/}
                            {/*    onClick={(event) => handleClick(event, true)}*/}
                            {/*>*/}
                            {/*    Grow your green economy*/}
                            {/*</Link>*/}
                            
                            <Link
                                className={`${Styles.navLinkLeft}`}
                                as={Link}
                                to="/marketplace"
                                href="/marketplace"
                                onMouseOver={() => changeHighLightText('Market', 'Main')}
                                onMouseLeave={() => changeHighLightText('Main', 'Market')}
                                onClick={(event) => handleClick(event,true)}
                            >
                                Marketplace
                            </Link>

                            <a className={`${Styles.navLinkLeft} ${path === "services/research-and-intelligence" ? Styles.current : null}`}
                                href={process.env.REACT_APP_GI_URI + "services/research-and-intelligence"} onClick={event => handleClick(event, true)}
                                onMouseOver={() => changeHighLightText('RI', 'Main')}
                                onMouseLeave={() => changeHighLightText('Main', 'RI')}
                            >
                                Research and intelligence
                            </a>
                            {/*<Link*/}
                            {/*    className={`${Styles.navLinkLeft}`}*/}
                            {/*    as={Link}*/}
                            {/*    to="/research"*/}
                            {/*    href="/research"*/}
                            {/*    onMouseOver={() => changeHighLightText('RI', 'Main')}*/}
                            {/*    onMouseLeave={() => changeHighLightText('Main', 'RI')}*/}
                            {/*    onClick={(event) => handleClick(event, true)}*/}
                            {/*>*/}
                            {/*    Research and intelligence*/}
                            {/*</Link>*/}
                            <a className={`${Styles.navLinkLeft} ${path === "services/accreditations" ? Styles.current : null}`}
                                href={process.env.REACT_APP_GI_URI + "services/accreditations"} onClick={event => handleClick(event, true)}
                                onMouseOver={() => changeHighLightText('Accr', 'Main')}
                                onMouseLeave={() => changeHighLightText('Main', 'Accr')}
                            >
                                Accreditations
                            </a>
                            {/*<Link*/}
                            {/*    className={`${Styles.navLinkLeft}`}*/}
                            {/*    as={Link}*/}
                            {/*    to="/accreditations"*/}
                            {/*    href="/accreditations"*/}
                            {/*    onMouseOver={() => changeHighLightText('Accr', 'Main')}*/}
                            {/*    onMouseLeave={() => changeHighLightText('Main', 'Accr')}*/}
                            {/*    onClick={(event) => handleClick(event, true)}*/}
                            {/*>*/}
                            {/*    Accreditations*/}
                            {/*</Link>*/}
                            <a className={`${Styles.navLinkLeft} ${path === "services/strategic-partnerships" ? Styles.current : null}`}
                                href={process.env.REACT_APP_GI_URI + "services/strategic-partnerships"} onClick={event => handleClick(event, true)}
                                onMouseOver={() => changeHighLightText('Strag', 'Main')}
                                onMouseLeave={() => changeHighLightText('Main', 'Strag')}
                            >
                                Strategic Partnerships
                            </a>
                            {/*<Link*/}
                            {/*    className={`${Styles.navLinkLeft}`}*/}
                            {/*    as={Link}*/}
                            {/*    onMouseOver={() => changeHighLightText('GI', 'Main')}*/}
                            {/*    onMouseLeave={() => changeHighLightText('Main', 'GI')}*/}
                            {/*>*/}
                            {/*    Green Skills*/}
                            {/*</Link>*/}
                            {/*<Link*/}
                            {/*    className={`${Styles.navLinkLeft}`}*/}
                            {/*    as={Link}*/}
                            {/*    onMouseOver={() => changeHighLightText('Consult', 'Main')}*/}
                            {/*    onMouseLeave={() => changeHighLightText('Main', 'Consult')}*/}
                            {/*>*/}
                            {/*    Consultancy*/}
                            {/*</Link>*/}

                        </div>
                        <div className={Styles.subSubMenu}>
                            {isMainShown &&
                                <div id="MainText">
                                    &nbsp;
                                </div>
                            }
                            {isSalesShown &&
                                <div id="SalesText">
                                    <h3>Increase your business profile!</h3>
                                    <p style={{ }} className={Styles.textDesc}>
                                        Our purpose is to improve the competitiveness and resilience of the green technologies and services sector.
                                        Showcase your products and services, access exclusive sales opportunities, and grow your business with expert support and networking and collaboration opportunities.
                                    </p>
                                </div>
                            }
                            {isMarketShown &&
                                <div id="MarketText">
                                    <h3>Find local green suppliers</h3>
                                    <p style={{ }} className={Styles.textDesc}>
                                        Search for local suppliers of green technologies and services to support your transition to net zero. Search by location,
                                        specialism and accreditation to find a local expert and connect directly with the business to request a quotation or call back. 
                                    </p>
                                </div>
                            }
                            {isNetZeroShown &&
                                <div id="NetZeroText">
                                    <h3>Need support to achieve net zero?</h3>
                                    <p style={{ }} className={Styles.textDesc}>
                                        At Green Economy we provide support and guidance to help your business develop and deliver an effective carbon reduction plan.
                                        We provide support to help you implement low and no cost solutions which will save your business money,
                                        whilst connecting you with local trusted partners to deliver your green technology projects.
                                    </p>
                                </div>
                            }
                            {isGrowGreenShown &&
                                <div id="GreenGrowText">
                                    <h3>Growing your local green economy?</h3>
                                    <p style={{ }} className={Styles.textDesc}>
                                        Green Economy works with local partners to develop green supply chains, providing sector mapping studies and bespoke support packages. 
                                    </p>
                                </div>
                            }
                            {isRIShown &&
                                <div id="RIText">
                                    <h3>Bespoke research and the latest industry news</h3>
                                    <p style={{ }} className={Styles.textDesc }>
                                        Get the latest industry news, insights and analysis impacting your business, or commission bespoke research including market research, consumer trends, sales forecasting, lead generation and competitor analysis.
                                    </p>
                                </div>
                            }
                            {isConsultShown &&
                                <div id="ConsultText">
                                    <h3>&nbsp;</h3>
                                    <p style={{ }} className={Styles.textDesc}>
                                        Commission research or economic development projects.
                                    </p>
                                </div>
                            }
                            {isGSShown &&
                                <div id="GIText">
                                    <h3>Grow your green business</h3>
                                    <p style={{  }} className={Styles.textDesc}>
                                        Green Leaders, peer networks<br/>
                                        Green Skills Centre<br/>
                                        Accreditations
                                    </p>
                                </div>
                            }
                            {isAccrShown &&
                                <div id="AccrText">
                                    <h3>Demonstrate your credentials</h3>
                                    <p style={{}} className={Styles.textDesc}>
                                        Stand out from the crowd and provide assurance to potential buyers with accreditations that demonstrate your business standards and competence to unlock new market opportunities. 
                                    </p>
                                </div>
                            }
                            {isStrategicShown &&
                                <div id="StragText">
                                    <h3>Work with Green Economy</h3>
                                    <p style={{}} className={Styles.textDesc}>
                                        Green Economy provides a limited number of partnership and commercial opportunities to available for organisations with a shared passion for growing the green economy and a vision to help businesses achieve net zero.
                                    </p>
                                </div>
                            }
                           
                            
                        </div>
                    </div>
            } 
        </>
    );
};
