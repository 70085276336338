import React, { useState, useEffect } from "react";
import { setDescription, setTitle } from "../../utils";
/*import bannerImg from "../../assets/images/banners/Events.png";*/
import bannerImg from "../../assets/images/banners/GE-Banner.png";
import marketplace from "../../assets/images/pageIcons/ge-marketplace.png"
import { PageCard } from "../../components/PageCard/PageCard";
import styles from "./Events.module.css"
import Banner from "../../components/Banner/Banner";
import { getEvents } from "../../utils";
import { Loader } from "../../components/Loader/Loader";
import { Error } from "../../components/Error/Error";
import EventImages from "./EventImages";
import {
    addEventsToLocalStorage,
    getEventsFromLocalStorage
} from "../../utils";

//window.addEventListener("beforeunload", () => {
//    localStorage.removeItem("event-results");
//});


export const Events = () => {

    const [inputs, setInputs] = useState({
        events: null
    });
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        setTitle("Green Economy | Events: Green skills development");
        setDescription("Green Economy events develop skills for green leaders and managers and simplifies the technologies at the heart of the net zero transition.")
    }, []);

    const fetchEvents = async () => {
        try {
            setIsLoading(true);
            let data = await getEvents();
            setInputs({ "events": data });
        } catch (error) {
            setError({ message: "Uh oh, looks like something's went wrong" });
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchEvents();
    }, []);

    return (
        <>
            <Banner
                title="GREEN ECONOMY EVENTS"
                text="BITESIZE LEARNING, PEER NETWORKS AND"
                subText="COLLABORATION OPPORTUNITIES"
                src={bannerImg}
                alt="Events"
                color='white'
                bgcolour="#02be7c"
            />
            <div className={styles.eventsContainer}>
                <h2 className="mb-2">GREEN ECONOMY EVENTS</h2>
                <p className="mb-2 FirstPara">Green Economy delivers a range of events, networking, workshops and briefings for businesses in the green technology sector and for those interested in learning more about low carbon products and services.</p>

                <p className="mb-2">Join us online or in-person for sessions focused on business growth best practice, tech-demystification, peer networking and collaboration, policy and trends analysis and meet the buyer events. </p>
                <h4 className="mb-2">Green Leaders</h4>
                <p className="mb-4">A programme of  <a className="PLinkTo" href={process.env.REACT_APP_GI_URI + "/green-leaders"} target="_blank" rel="noopener noreferrer">events</a> designed to help you seize new market opportunities and grow your business.</p>

                {error &&
                     <Error error={error} id="event-error" page="events-page" />
                }

                {isLoading &&
                     <Loader page="events" />
                }

                {!isLoading && inputs && inputs.events &&
                    <div className={styles.eventsCards}>
                            {inputs.events.map((event) => (
                                <div className={styles.eventsCard} key={event.id}>
                                    <a href={"https://greeneconomy.zohobackstage.eu/" + event.eventKey} target="_blank" rel="noopener noreferrer">
                                        <div className={styles.eventCardImg}>
                                            <EventImages category={event.category} />
                                            <div className={styles.cardEventDate}>
                                                <h4>{event.eventDay}</h4>
                                                <p>{event.eventMonthYear}</p>
                                            </div>
                                        </div>
                                    </a>
                                    <div className={styles.cardBody} style={{ maxWidth: '100%', height: 'auto' } }>
                                        
                                        <p><span className="gcicon-calendar"></span> {event.startDate} | {event.startTime} - {event.endTime}</p>
                                        <p className={styles.eventsTitle}><a href={"https://greeneconomy.zohobackstage.eu/" + event.eventKey} target="_blank" rel="noopener noreferrer">{event.name}</a></p>
                                        <p><span className="gcicon-pin"></span> Location: {event.location}</p>
                                        <p className={styles.eventsCardDetail}>{event.summary}</p>
                                        {event.eventKey &&
                                            <div className={ styles.findOutMore } >
                                                <a href={"https://greeneconomy.zohobackstage.eu/" + event.eventKey} target="_blank" rel="noopener noreferrer" className={styles.findOutMore }>Find out more <span className="gcicon-right"></span></a>
                                            </div>
                                        }
                                    </div>
                                </div>
                            ))}
                    </div>
                }

                <PageCard
                    width="100%"
                    header="Marketplace"
                    text="Use our directory of installers, equipment suppliers and solution providers to make an informed decision on your sustainability projects and support your local green economy"
                    page="marketplace"
                    hasLogo={true}
                    logo={marketplace}
                    link="marketplace"
                    styling="marketplace"
                />
            </div>
        </>
    );
};
